import React, { Component } from "react";
import { Link } from "react-router-dom";
import {API_URL} from "../../constants/Config"
export default class ProductImage extends Component {
  render() {
    return (
      <Link
        className="link-to-product"
        to={`/product/${this.props.product.id}/detail`}
      >
        <div className="product-image shadow-lg justify-content-center">
          <div id="product-img-block">
            <img
              // className="img-fluid"
              style={{ width: "100%" }}
              src={`${this.props.product.img1}`}
              alt={this.props.product.description}
            ></img>
          </div>

          {/* <p id="type">{this.props.product.type}</p> */}
          <p>
            <b>{this.props.product.name}</b>
          </p>
          <p>{this.props.product.size}</p>
        </div>
      </Link>
    );
  }
}
