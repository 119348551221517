import React, { Component } from "react";
const dressers = require("../../Assets/dresser.jpg");
const bedFrame = require("../../Assets/bed frame.jpg");
const chest = require("../../Assets/chest.jpg");
const mattress = require("../../Assets/mattress.jpg");
const other = require("../../Assets/orther.jpg");
const cotton = require("../../Assets/cotton.jpg");

export default class ProductBanner extends Component {
  render() {
    return (
      <div className="container-fluid product-display justify-content-center">
        {/* <h3>Our Product</h3> */}
        <div className="row product-display-row justify-content-center">
          <div className="col-lg-4 col-6 sub-banner">
            <a href="/category/Bed Frame/page/1">
              <img
                alt=""
                className="homepage-image shadow-lg"
                src={bedFrame}
              ></img>
            </a>
            <a className="reksai" href="/category/Bed Frame/page/1">
              View products
            </a>
          </div>
          <div className="col-lg-4 col-6 sub-banner">
            <a href="/category/Dresser/page/1">
              <img
                alt=""
                className="homepage-image shadow-lg"
                src={dressers}
              ></img>
            </a>
            <a className="reksai" href="/category/Dresser/page/1">
              View products
            </a>
          </div>
          <div className="col-lg-4 col-6 sub-banner">
            <a href="/category/Chest/page/1">
              <img
                alt=""
                className="homepage-image shadow-lg "
                src={chest}
              ></img>
            </a>
            <a className="reksai" href="/category/Chest/page/1">
              View products
            </a>
          </div>
          <div className="col-lg-4 col-6 sub-banner">
            <a href="/category/Mattress/page/1">
              <img
                alt=""
                className="homepage-image shadow-lg"
                src={mattress}
              ></img>
            </a>
            <a className="reksai" href="/category/Mattress/page/1">
              View products
            </a>
          </div>
          <div className="col-lg-4 col-6 sub-banner">
            <a href="/category/Cotton Batting And Fiber/page/1">
              <img
                alt=""
                className="homepage-image shadow-lg"
                src={cotton}
              ></img>
            </a>
            <a
              className="reksai"
              href="/category/Cotton Batting And Fiber/page/1"
            >
              View products
            </a>
          </div>
          <div className="col-lg-4 col-6 sub-banner">
            <a href="/category/Other/page/1">
              <img
                alt=""
                className="homepage-image shadow-lg"
                src={other}
              ></img>
            </a>
            <a className="reksai" href="/category/Other/page/1">
              View products
            </a>
          </div>
        </div>
        <hr></hr>
      </div>
    );
  }
}
