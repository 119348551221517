import React, { Component } from "react";
import * as actions from "../../actions/index";
import { connect } from "react-redux";
import Menu from "../../components/Menu/Menu";
import axios from "axios";
import validate from "../../utils/validate";
import "../../Style/admin.css";
import { API_URL } from "../../constants/Config";
class ProductActionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      code: "",
      name: "",
      description: "",
      size: "",
      color: "",
      price: 0,
      type: "",
      image: [],
      note: "",
      bestseller: 0,
      newarrival: 0,
      img1: "",
      img2: "",
      img3: "",
      img4: ""
    };
    this.file = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps.itemEditing, "item");
    if (nextProps && nextProps.itemEditing) {
      this.setState({
        id: nextProps.itemEditing.id,
        name: nextProps.itemEditing.name,
        code: nextProps.itemEditing.code,
        description: nextProps.itemEditing.description,
        size: nextProps.itemEditing.size,
        color: nextProps.itemEditing.color,
        price: nextProps.itemEditing.price,
        type: nextProps.itemEditing.type,
        note: nextProps.itemEditing.note,
        bestseller: nextProps.itemEditing.bestseller,
        newarrival: nextProps.itemEditing.newarrival,
        img1: nextProps.itemEditing.img1,
        img2: nextProps.itemEditing.img2,
        img3: nextProps.itemEditing.img3,
        img4: nextProps.itemEditing.img4
      });
    }
  }

  componentDidMount() {
    var { match } = this.props;
    if (match) {
      var id = match.params.id;
      this.props.editProduct(id);
    }
  }

  onChange = e => {
    var target = e.target;
    var name = target.name;
    var value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value
    });
    console.log(name + " " + value);
  };

  imageOnChange = evt => {
    evt.preventDefault();
    const target = evt.target;
    const files = target.files;
    let result = 0;
    for (let i = 0; i < files.length; i++) {
      if (
        files[i].name.includes("jpg") ||
        files[i].name.includes("jpeg") ||
        files[i].name.includes("png")
      ) {
        result += 1;
      } else {
        result = 0;
      }
    }
    if (result !== files.length) {
      result = 0;
    }
    if (result === 0) {
      alert("Images are wrong format");
    }
    this.file = files;
  };

  onSave = e => {
    var {
      id,
      code,
      name,
      image,
      description,
      size,
      color,
      price,
      type,
      note,
      bestseller,
      newarrival,
      img1,
      img2,
      img3,
      img4
    } = this.state;
    var { history } = this.props;
    if (type === "") {
      type = "Bed Frame";
    }
    e.preventDefault();
    var isValid = true;
    var result =
      isValid &&
      validate(name) &&
      validate(type) &&
      validate(code) &&
      validate(price);
    var product = {
      id: id,
      code: code,
      name: name,
      type: type,
      image: image,
      description: description,
      size: size,
      color: color,
      price: price,
      note: note,
      bestseller: bestseller,
      newarrival: newarrival,
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4
    };
    console.log(product, "product");
    if (product && result === true) {
      if (id) {
        if (this.file) {
          const formData = new FormData();
          for (let i = 0; i < this.file.length; i++) {
            formData.append("image", this.file[i]);
          }
          const config = {
            headers: {
              "content-type": "multipart/form-data"
            }
          };
          axios
            .post(API_URL+"/product/upload", formData, config)
            .then(res => {
              this.setState({ image: res.data.fileNameInServer });
              product.img1 = this.state.image[0];
              product.img2 = this.state.image[1];
              product.img3 = this.state.image[2];
              product.img4 = this.state.image[3];
              console.log(product, "ppp");
            })
            .then(() => {
              this.props.updateProduct(product);
              history.push("/product-list");
            });
        } else {
          this.props.updateProduct(product);
          history.push("/product-list");
        }
      } else {
        const formData = new FormData();
        for (let i = 0; i < this.file.length; i++) {
          formData.append("image", this.file[i]);
        }
        const config = {
          headers: {
            "content-type": "multipart/form-data"
          }
        };
        axios
          .post(API_URL+"/product/upload", formData, config)
          .then(res => {
            this.setState({ image: res.data.fileNameInServer });
            product.image = this.state.image;
          })
          .then(() => {
            this.props.addProduct(product);
            history.push("/product-list");
          });
      }
    } else {
      alert("Please input right format");
    }
  };

  render() {
    var checkedBS,
      checkedNA = false;
    var {
      code,
      name,
      type,
      description,
      note,
      size,
      color,
      price,
      bestseller,
      newarrival,
      img1,
      img2,
      img3,
      img4
    } = this.state;

    return (
      <div className="container-fluid">
        <Menu />
        <div className="add-container">
          <div
            className="col-xs-6 col-sm-6 col-md-6 col-lg-6 shadow-lg"
            id="add-ctn"
          >
            <form onSubmit={this.onSave} encType="multipart/form-data">
              <div className="row">
                <div className="form-group col-6">
                  <label>Code</label>
                  <input
                    type="text"
                    className={`form-control ${
                      this.state.code ? "is-valid" : "is-invalid"
                    }`}
                    name="code"
                    value={code}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label>Name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      this.state.name ? "is-valid" : "is-invalid"
                    }`}
                    name="name"
                    value={name}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Type</label>
                    <br></br>
                    <select
                      className={`btn`}
                      name="type"
                      onChange={this.onChange}
                    >
                      <option
                        selected={type === "Bed Frame" ? true : false}
                        value="Bed Frame"
                      >
                        BED FRAME
                      </option>
                      <option
                        selected={type === "Dresser" ? true : false}
                        value="Dresser"
                      >
                        DRESSER
                      </option>
                      <option
                        selected={type === "Chest" ? true : false}
                        value="Chest"
                      >
                        CHEST
                      </option>
                      <option
                        selected={type === "Mattress" ? true : false}
                        value="Mattress"
                      >
                        Mattress
                      </option>
                      <option
                        selected={
                          type === "Cotton Batting And Fiber" ? true : false
                        }
                        value="Cotton Batting And Fiber"
                      >
                        COTTON BATTING AND FIBER
                      </option>
                      <option value="Other">OTHER</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Size</label>
                    <input
                      type="text"
                      className="form-control"
                      name="size"
                      value={size}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={description}
                  onChange={this.onChange}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Price</label>
                    <div className="row">
                      <div className="col-10">
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          value={price}
                          onChange={this.onChange}
                        />
                      </div>
                      <div>
                        <p className="col-2 col2">$</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Color</label>
                    <input
                      type="text"
                      className="form-control"
                      name="color"
                      value={color}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Note</label>
                <input
                  type="text"
                  className="form-control"
                  name="note"
                  value={note}
                  onChange={this.onChange}
                />
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="bestseller"
                    checked={this.state.bestseller}
                    onChange={this.onChange}
                  />
                  Best Seller
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="newarrival"
                    checked={this.state.newarrival}
                    onChange={this.onChange}
                  />
                  New Arrival
                </label>
              </div>
              <div className="form-group">
                <label>Image</label>
                {img1 !== "" ? (
                  <td>
                    {img1 !== null ? (
                      <img src={`${img1}`} height="90" width="90" />
                    ) : null}
                    {img2 !== null ? (
                      <img src={`${img2}`} height="90" width="90" />
                    ) : null}
                    {img3 !== null ? (
                      <img src={`${img3}`} height="90" width="90" />
                    ) : null}
                    {img4 !== null ? (
                      <img src={`${img4}`} height="90" width="90" />
                    ) : null}
                  </td>
                ) : null}

                <input
                  type="file"
                  multiple
                  className={`form-control ${
                    this.state.code ? "is-valid" : "is-invalid"
                  }`}
                  name="image"
                  onChange={this.imageOnChange}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { itemEditing: state.itemEditing };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addProduct: product => {
      dispatch(actions.actAddProductRequest(product));
    },
    editProduct: id => {
      dispatch(actions.actGetProductRequest(id));
    },
    updateProduct: product => {
      dispatch(actions.actUpdateProductRequest(product));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionPage);
