import React, { Component } from "react";
import ProductDisplay from "../Homepage/ProductDisplay";
import SubImage from "./SubImage";
import { API_URL } from "../../constants/Config";
export default class ImageContainer extends Component {
  state = {
    isChoosen: ["is-choosen", "", "", ""],
    mainImage: null
  };

  changeImage = link => {
    this.setState({ mainImage: link });
  };

  _onClick = index => {
    let Choosen = this.state.isChoosen;
    for (let i = 0; i <= 3; i++) {
      if (i == index) {
        Choosen[i] = "is-choosen";
      } else {
        Choosen[i] = "";
      }
    }
    this.setState({ isChoosen: Choosen });
  };

  render() {
    const product = this.props.product ? this.props.product : "";
    const link = this.state.mainImage
      ? this.state.mainImage
      : `${product.img1}`;
    return (
      <div className="image-container shadow-lg">
        <div className="detail-main-image">
          <img src={link}></img>
        </div>
        <div className="row">
          <SubImage
            changeImage={this.changeImage}
            _onClick={() => this._onClick(0)}
            isChoosen={this.state.isChoosen[0]}
            product={`${product.img1}`}
          ></SubImage>
          <SubImage
            changeImage={this.changeImage}
            _onClick={() => this._onClick(1)}
            isChoosen={this.state.isChoosen[1]}
            product={`${product.img2}`}
          ></SubImage>
          <SubImage
            changeImage={this.changeImage}
            _onClick={() => this._onClick(2)}
            isChoosen={this.state.isChoosen[2]}
            product={`${product.img3}`}
          ></SubImage>
          <SubImage
            changeImage={this.changeImage}
            _onClick={() => this._onClick(3)}
            isChoosen={this.state.isChoosen[3]}
            product={`${product.img4}`}
          ></SubImage>
        </div>
      </div>
    );
  }
}
