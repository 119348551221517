import React, { Component } from "react";
import Navbar from "../../components/Homepage/Navbar";
import Banner from "../../components/Homepage/Banner";
import BestSeller from "../../components/Homepage/BestSeller";
import ProductBanner from "../../components/Homepage/ProductBanner";
import AboutUs from "../../components/Homepage/AboutUs";
import Footer from "../../components/Homepage/Footer";
import NewArrival from "../../components/Homepage/NewArrival";
import Slogan from "../../components/Homepage/Slogan";

class HomePage extends Component {

  render() {
    return (
      <div className="container-fluid homepage">
        <Navbar></Navbar>
        <Banner></Banner>
        <ProductBanner></ProductBanner>
        <BestSeller></BestSeller>
        <NewArrival></NewArrival>
        {/* <AboutUs></AboutUs> */}
        <Slogan></Slogan>
        <Footer></Footer>
      </div>
    );
  }
}

export default HomePage;
