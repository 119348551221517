import React, { Component } from 'react'
import Navbar from '../../components/Homepage/Navbar'
import Footer from '../../components/Homepage/Footer'
import img1 from "../../Assets/1.jpg"
import img2 from "../../Assets/2.jpg"
import img3 from "../../Assets/3.jpg"
import "../../Style/aboutus.css"
export default class AboutUs extends Component {
  render() {
    return (
      <div className="aboutus-container">
        <Navbar></Navbar>
        <div id="magic1" className="magic-swipe">
          <img src={img1}></img>
        </div>
        <div className="magic-swipe">
          <img src={img2}></img>
        </div>
        <div className="magic-swipe">
          <img src={img3}></img>
        </div>
        <div className="footer-container">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}
