import React, { Component } from "react";
import Navbar from "../../components/Homepage/Navbar";
import { actFetchProductsRequest } from "../../actions/index";
import { connect } from "react-redux";
import ImageContainer from "../../components/ProductDetail/ImageContainer";
import "../../Style/detail.css";
import RelatedProducts from "../../components/ProductDetail/RelatedProducts";
import Slogan from "../../components/Homepage/Slogan";
import Footer from "../../components/Homepage/Footer";
import ModalBtn from "../../components/ProductDetail/ModalBtn";
class ProductDetails extends Component {
  componentDidMount() {
    this.props.actFetchProductsRequest();
    window.scrollTo(0, 0);
  }
  render() {
    const product = this.props.products.find(
      product => product.id == this.props.match.params.id
      );
      const index = this.props.products.filter(prd=>prd.type == product.type).findIndex(
        product => product.id == this.props.match.params.id
        );

    let relatedProducts;
    let filteredProducts = this.props.products.filter(prd => prd.type === product.type && prd.id !== product.id);
    if (index === 0) {
      relatedProducts = filteredProducts.slice(index , index + 5);
    }
    else if (index === 1) {
      relatedProducts = filteredProducts.slice(index - 1, index + 4);

    }
    else if (index === this.props.products.filter(prd => prd.type === product.type).length - 1 ) {
      relatedProducts = filteredProducts.slice(index - 4, index);
    }
    else if (index === this.props.products.filter(prd => prd.type === product.type).length - 2) {
      relatedProducts = filteredProducts.slice(index - 3, index + 1);
    }
    else {
      relatedProducts = filteredProducts.slice(index - 2, index + 3);
    }

    // const relatedProducts = this.props.products.filter(prd=>prd.type == product.type).slice(index+1,index+5);

    if (product) {
      return (
        <div className="container-fluid">
          <Navbar></Navbar>
          <div className="row product-detail-container">
            <div className="col-lg-6 col-12">
              <ImageContainer product={product}></ImageContainer>
            </div>
            <div className="col-lg-6 col-12">
              <div className="info-container">
                <h3 className="name">{product.name}</h3>
                <a href={`/product/category/${product.type}/page/1`}>
                  {product.type}
                </a>
                <p className="size">{product.size}</p>
                <hr></hr>
                <p className="desc-title">
                  <b>DESCRIPTION</b>
                </p>
                {/* <p className="desc">{product.description}</p> */}
                <p style={{minHeight: 180}} className="form-control desc disabled" rows="5" id="comment">
                  {product.description}
                </p>
                <ModalBtn></ModalBtn>
              </div>
              <div className="related-products">
                <RelatedProducts
                  relatedProducts={relatedProducts}
                ></RelatedProducts>
              </div>
            </div>
          </div>
          <Slogan></Slogan>
          <Footer></Footer>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
const mapStateToProps = state => {
  return { products: state.products };
};

export default connect(mapStateToProps, { actFetchProductsRequest })(
  ProductDetails
);
