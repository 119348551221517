import React, { Component } from 'react'

export default class AboutUs extends Component {
  render() {
    return (
      <div className="row justify-content-center about-us" id="about-us">
        <div className="bg-aboutus animated fadeInUp delay-5s">
          <img
            className="bg-aboutus-image "
            src={require("../../Assets/about.jpg")}
            alt=""
          ></img>
        </div>
        <div className="col-8 content-aboutus">
          <p>
            Home Furniture Design started as a small furniture factory in 1999.
            Beside with providing high quality of wood furniture to many
            retailers in North California, we also produce cotton batting, wool,
            polyester fiber… to other mattress factories, upholstery supplies,
            Yoga factories... all around the country.
          </p>
          <p>
            We are proud to bring our products to the customer with competitive
            price and great quality, where they can choose to order furniture
            with the most suitable size and color for their living space. With
            our effort in improving product design and quality by new
            technology, modern machinery and especially skilled workers, HDF
            pride ourselves on bringing the best furniture shopping experience
            to the customer.{" "}
          </p>
        </div>
      </div>
    );
  }
}
