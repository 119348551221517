import React from "react";
import HomePage from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import ProductListPage from "./pages/ProductListPage/ProductListPage";
import ProductActionPage from "./pages/ProductActionPage/ProductActionPage";
import UserActionPage from "./pages/UserActionPage/UserActionPage";
import UserListPage from "./pages/UserListPage/UserListPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ProductsList from "./pages/HomePage/ProductsList";
import ProductDetails from "./pages/HomePage/ProductDetails";
import AllPage from "./pages/AllPage/AllPage";
import DresserPage from "./pages/DresserPage/DresserPage";
import ChestPage from "./pages/ChestPage/ChestPage";
import MattressPage from "./pages/MattressPage/MattressPage";
import BedFramePage from "./pages/BedFramePage/BedFramePage";
import CottonPage from "./pages/CottonPage/CottonPage";
import OtherPage from "./pages/OtherPage/OtherPage";
import AboutUs from "./pages/AboutUs/AboutUsPage";

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <HomePage />,
  },
  {
    path: "/category/:type/page/:page",
    exact: false,
    main: ({ match }) => <ProductsList match={match} />,
  },
  {
    path: "/product-list",
    exact: false,
    main: ({ history }) => <ProductListPage history={history} />,
  },
  {
    path: "/product/add",
    exact: false,
    main: ({ history }) => <ProductActionPage history={history} />,
  },
  {
    path: "/product/:id/edit",
    exact: false,
    main: ({ match, history }) => (
      <ProductActionPage match={match} history={history} />
    ),
  },
  {
    path: "/product/:id/detail",
    exact: false,
    main: ({ match, history }) => (
      <ProductDetails match={match} history={history}></ProductDetails>
    ),
  },
  {
    path: "/user-list",
    exact: false,
    main: ({ history }) => <UserListPage history={history} />,
  },
  {
    path: "/user/add",
    exact: false,
    main: ({ history }) => <UserActionPage history={history} />,
  },
  {
    path: "/login",
    exact: false,
    main: ({ history }) => <LoginPage history={history} />,
  },
  {
    path: "/all",
    exact: false,
    main: () => <AllPage />,
  },
  {
    path: "/dresser",
    exact: false,
    main: () => <DresserPage />,
  },
  {
    path: "/chest",
    exact: false,
    main: () => <ChestPage />,
  },
  {
    path: "/bed-frame",
    exact: false,
    main: () => <BedFramePage />,
  },
  {
    path: "/cotton-batting-and-fiber",
    exact: false,
    main: () => <CottonPage />,
  },
  {
    path: "/mattress",
    exact: false,
    main: () => <MattressPage />,
  },
  {
    path: "/other",
    exact: false,
    main: () => <OtherPage />,
  },
  {
    path: "/aboutus",
    exact: false,
    main: () => <AboutUs />,
  },
  {
    path: "",
    exact: false,
    main: () => <NotFoundPage />,
  },
];

export default routes;
