import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
export default class ModalBtn extends Component {
  state = {
    show:  false
  };
  handleShow = ()=>this.setState({show:true});
  handleClose = ()=>this.setState({show:false});
  render() {
    return (
      <div>
        <Button
          variant="btn btn-dark rounded-0 btn-lg"
          className="btn btn-dark rounded-0 btn-lg vayne"
          onClick={this.handleShow}
        >
          Contact Us
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact us</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please have a call to <b>(510) 562-3738</b> to get best service</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
