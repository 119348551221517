import React, { Component } from "react";
import * as actions from "../../actions/index";
import { connect } from "react-redux";
import callApi from "../../utils/apiCaller";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            access_token: ""
        };
    }
    
    componentDidMount(){
        var access_token = localStorage.getItem("access_token");
        var token = {
            access_token: access_token
        }
        var { history } = this.props;
        if(access_token) {
            callApi("auth/check", "POST", token).then(res => {
                if(res.data.success === 1){
                    var data = res.data.user;
                    callApi("auth/login", "POST", data).then(res => {
                            history.push("/user-list");
                        }
                    );
                } else {
                    history.push("/login");
                }
            })
        }
    }

    onChange = e => {
        var target = e.target;
        var name = target.name;
        var value = target.type === "checkbox" ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };

    onSave = e => {
        var { username, password, access_token} = this.state;
        var { history } = this.props;
        e.preventDefault();
        var data = {
            username: username,
            password: password
        };
        if (data) {
            callApi("auth/login", "POST", data).then(res => {
                if(res.data.success === 0){
                    alert("Sai mật khẩu!")
                } else {
                    localStorage.setItem("access_token", res.data.access_token);
                    history.push("/user-list");
                }
            });
        }
    };

    render() {
        return (
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <form onSubmit={this.onSave}>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={this.onChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Login
                    </button>
                </form>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        login: data => {
            dispatch(actions.loginApiRequest(data));
        },
        // editProduct: id => {
        //     dispatch(actions.actGetProductRequest(id));
        // },
        // updateProduct: product => {
        //     dispatch(actions.actUpdateProductRequest(product));
        // }
    };
};

export default connect(null, 
    mapDispatchToProps
)(LoginPage);
